import React from 'react';
import {
  MastercardIcon,
  VisaIcon,
  HipercardIcon,
  EloIcon,
  AxpIcon,
  DinerIcon,
  JcbIcon,
} from '../icons';

const getCardIcon = (brand) => {
  switch (brand) {
    case 'MASTERCARD':
      return <MastercardIcon />;
    case 'VISA':
      return <VisaIcon />;
    case 'HIPERCARD':
      return <HipercardIcon />;
    case 'ELO':
      return <EloIcon />;
    case 'AMERICAN_EXPRESS':
      return <AxpIcon />;
    case 'DINERS_CLUB':
      return <DinerIcon />;
    case 'JCB':
      return <JcbIcon />;
    default:
      return <span />;
  }
};

const brandList = [
  {
    id: 2,
    name: 'Visa',
  },
  {
    id: 1,
    name: 'MasterCard',
  },
  {
    id: 11,
    name: 'Elo',
  },
  {
    id: 12,
    name: 'Hipercard',
  },
  {
    id: 3,
    name: 'American Express',
  },
  {
    id: 9,
    name: 'Diners Club',
  },
  {
    id: 22,
    name: 'JCB',
  },
];

const getCardIconByCode = (code) => {
  switch (code) {
    case 1:
      return <MastercardIcon />;
    case 2:
      return <VisaIcon />;
    case 12:
      return <HipercardIcon />;
    case 11:
      return <EloIcon />;
    case 3:
      return <AxpIcon />;
    case 9:
      return <DinerIcon />;
    case 22:
      return <JcbIcon />;
    default:
      return <span />;
  }
};

export { getCardIcon, brandList, getCardIconByCode };
